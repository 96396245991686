<!--
File: InventoryEvents.vue
Description: show list of Inventory events entered in the DB.
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <md-input type="search" class="mb-3" clearable style="width: 200px"
                :placeholder="$t('label.search_records')" v-model="searchQuery"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addNewInventoryTypeEvent"> {{ $t('buttons.add') }} </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-table :value="queriedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort"
          class="paginated-table table-striped table-hover">
          <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')">
          </md-table-empty-state>
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="description">
              {{ item.description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div>
                <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                  @click.native="editInventoryEvent(item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
          </p>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label for="pages">{{ $t('label.per_page') }}</label>
            <md-select v-model="pagination.perPage" name="pages">
              <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
          :per-page="pagination.perPage" :total="total"></pagination>
      </md-card-actions>
    </md-card>
    <md-dialog :md-active.sync="showEditDlg" :md-click-outside-to-close="false">
      <md-dialog-title v-if="oper === 'upd'">{{ $t('edit inventory event') }}</md-dialog-title>
      <md-dialog-title v-else>{{ $t('add new inventory event') }}</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>{{ $t('stdCols.description') + ' (RU)' }}</label>
          <md-input v-model="description" type="text" data-vv-name="description" required
            v-validate="modelValidations.description"></md-input>
        </md-field>

        <md-field>
          <label>{{ $t('stdCols.description') + ' (EN)' }}</label>
          <md-input v-model="description_en" type="text" data-vv-name="description_en" required
            v-validate="modelValidations.description_en"></md-input>
        </md-field>

      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
          {{ $t('buttons.save') }}
        </md-button>
        <md-button class="md-accent" @click.stop.prevent="showEditDlg = false">
          {{ $t('buttons.close') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
  import { Pagination } from '@/components'
  import Fuse from 'fuse.js'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'

  export default {
    name: 'inventory-types-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        inventory_type_event_id: null,
        description: null,
        description_en: null,
        showEditDlg: false,
        oper: null,
        modelValidations: {
          description: { required: true, min: 3 },
          description_en: { required: true, min: 3 }
        },
        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 30, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['description'],
        searchedData: [],
        currentSort: 'description',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },

    components: {
      Pagination,
    },

    mounted() {
      this.reloadList()
    },

    methods: {
      reloadList() {
        this.$store.dispatch('LOAD_INVENTORY_EVENTS_LIST').then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.inventoryEventsList, {
            keys: this.propsToSearch,
            threshold: 0.3
          })
        })
      },

      editInventoryEvent(item) {
        this.oper = 'upd'
        this.inventory_type_event_id = item.inventory_type_event_id
        this.$store.dispatch('LOAD_INVENTORY_EVENT_BY_ID', this.inventory_type_event_id).then((res) => {
          const invEvent = Array.isArray(res) ? res[0] : res
          this.description = invEvent.description
          this.description_en = invEvent.description_en
        })
        this.showEditDlg = true
      },

      addNewInventoryTypeEvent() {
        this.oper = 'add'
        this.description = null
        this.description_en = null
        this.showEditDlg = true
      },

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const invEvent = {
          description: this.description,
          description_en: this.description_en
        }
        const payload = this.oper === 'add' ? invEvent : { id: this.inventory_type_event_id, invEvent: invEvent }

        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_INVENTORY_EVENT`, payload)
        } catch (err) {
          errDesc = err
        }
        this.savedMessage(errDesc, this.$t('label.inventory_event'), this.description, this.description_en)
        this.reloadList()
        this.showEditDlg = false
      },

      handleDelete(item) {

      }
    },

    computed: {
      amIAdmin() {
        return this.$store.getters.amIAdmin
      },
      inventoryEventsList() {
        return this.$store.state.InventoryEvents.list
      },
      saveBtnDisabled() {
        return !this.description || this.description == '' || !this.description_en || this.description_en == ''
      },
      queriedData() {
        const result = !this.searchQuery ? this.inventoryEventsList : this.searchedData ? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.inventoryEventsList.length
      },
    },

    watch: {
      searchQuery(value) {
        if (this.inventoryEventsList.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.inventoryEventsList
      }
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>